import Parallax from 'parallax-js'

var ilust= document.querySelectorAll('.ilust');
var lista= [];
for (var i= 0; i<ilust.length;i++) {
    lista.push (new Parallax(ilust[i]));
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

